// const BASEURL = 'https://corrugated.hp-blueprint.com/';
const BASEURL = 'https://corrugated-dev.hp-blueprint.com/';
const BASEAPIURL = 'https://corrugated.hp-blueprint.com/api/';
const EXT = '.php';
// const CMS = 'https://admin-corrugated.hp-blueprint.com/';
const CMS = 'https://corrugated-dev-api.hp-blueprint.com/';
// const CMS = 'http://localhost:8055/';

export {
  BASEURL,
  BASEAPIURL,
  EXT,
  CMS,
};
